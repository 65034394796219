body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Qr {
  width: 233px;
  height: 245px;
}

body {
  margin: 0px !important;

  overflow-x: hidden !important;
}

@media (max-width: 768px) {
  .step-image {
    height: 25px;
  }
}

@media (max-width: 320px) {
  body {
    margin: 0px !important;

    overflow-x: hidden !important;
  }
}

.pointer {
  cursor: pointer;
}

.eliotpro {
  font-family: "eliotpro" !important;
}

.eliotprobold {
  font-family: "eliotprobold" !important;
}
